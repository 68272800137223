import * as j from '@mojotech/json-type-validation';
import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';

import { AttemptCreationContext } from '~providers/AttemptProvider/domain';

// Preview campaign settings pushPreviewSeconds property defaults and ranges
export enum PushPreviewSeconds {
  Min = 30,
  Max = 600,
  Infinite = 0,
}

// Preview campaign settings ringOutSeconds property defaults and ranges
export enum RingOutSeconds {
  Min = 20,
  Max = 600,
  Infinite = 0,
}

export enum DiallerType {
  Connect = 'connect',
  SIP = 'sip',
}

export enum CampaignType {
  Preview = 'preview',
  Predictive = 'predictive',
}

export const typeIdsToDisplayText = {
  [DiallerType.Connect]: 'Connect',
  [DiallerType.SIP]: 'SIP',
  [CampaignType.Preview]: 'Preview',
  [CampaignType.Predictive]: 'Predictive',
};

export enum LeadStatusType {
  Contacted = 'contacted',
  Washed = 'washed',
  Filtered = 'filtered',
  OptOut = 'opt-out',
  NoEndpoints = 'no-endpoints',
  Excluded = 'excluded',
  Duplicate = 'duplicate',
  Building = 'building',
  AwaitingRetry = 'awaiting-retry',
  AwaitingStart = 'awaiting-start',
  Ready = 'ready',
  OutOfHours = 'out-of-hours',
  Finished = 'finished',
  FinishedToday = 'finished-today',
  Callback = 'callback',
  NoSkilledAgents = 'no-skilled-agents',
  Assigned = 'assigned',
  Initiated = 'initiated',
  Connected = 'connected',
  InvalidEndpoint = 'invalid-endpoint',
  Disconnected = 'disconnected',
  Removed = 'removed',
  Expired = 'expired',
  AwaitingSMS = 'awaiting-sms',
  InactiveList = 'inactive-list',
  MissedCallback = 'missed-callback',
  InQueue = 'in-queue',
  AwaitingCallback = 'awaiting-callback',
  Replaced = 'replaced',
  Snoozed = 'snoozed',
}

export enum LeadEndpointStatusType {
  Building = 'building',
  Washed = 'washed',
  Duplicate = 'duplicate',
  Excluded = 'excluded',
  Ready = 'ready',
  Finished = 'finished',
  FinishedToday = 'finished-today',
  WaitingInterval = 'waiting-interval',
  Assigned = 'assigned',
  Initiated = 'initiated',
  Connected = 'connected',
  Disconnected = 'disconnected',
  Invalid = 'invalid',
  OptOut = 'opt-out',
  Replaced = 'replaced',
  OutOfHours = 'out-of-hours',
}

type StatusColor = {
  baseColor: string;
  lightColor: string;
};

export const leadStatusColor: { [key in LeadStatusType]: StatusColor } = {
  [LeadStatusType.Assigned]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadStatusType.AwaitingRetry]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
  [LeadStatusType.AwaitingStart]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
  [LeadStatusType.Building]: {
    baseColor: blue['600'],
    lightColor: blue['100'],
  },
  [LeadStatusType.Callback]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
  [LeadStatusType.Connected]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadStatusType.Contacted]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.Duplicate]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadStatusType.Excluded]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.Filtered]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.Finished]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.FinishedToday]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.Initiated]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadStatusType.NoEndpoints]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadStatusType.NoSkilledAgents]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadStatusType.OptOut]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.OutOfHours]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
  [LeadStatusType.Ready]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadStatusType.Washed]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.InvalidEndpoint]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadStatusType.Disconnected]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadStatusType.Removed]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.Expired]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.AwaitingSMS]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.InactiveList]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.MissedCallback]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadStatusType.InQueue]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadStatusType.AwaitingCallback]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadStatusType.Replaced]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadStatusType.Snoozed]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
};

export const leadEndpointStatusColor: { [key in LeadEndpointStatusType]: StatusColor } = {
  [LeadEndpointStatusType.Building]: {
    baseColor: blue['600'],
    lightColor: blue['100'],
  },
  [LeadEndpointStatusType.Washed]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadEndpointStatusType.Duplicate]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadEndpointStatusType.Excluded]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadEndpointStatusType.Ready]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadEndpointStatusType.Finished]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadEndpointStatusType.FinishedToday]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadEndpointStatusType.WaitingInterval]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
  [LeadEndpointStatusType.Assigned]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadEndpointStatusType.Initiated]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadEndpointStatusType.Connected]: {
    baseColor: green['600'],
    lightColor: green['100'],
  },
  [LeadEndpointStatusType.Disconnected]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadEndpointStatusType.Invalid]: {
    baseColor: red['600'],
    lightColor: red['100'],
  },
  [LeadEndpointStatusType.OptOut]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadEndpointStatusType.Replaced]: {
    baseColor: grey['600'],
    lightColor: grey['100'],
  },
  [LeadEndpointStatusType.OutOfHours]: {
    baseColor: amber['600'],
    lightColor: amber['100'],
  },
};

export enum OutcomeType {
  AnsweringMachine = 'answering-machine',
  AssignmentExpired = 'assignment-expired',
  Callback = 'callback',
  Contacted = 'contacted',
  Error = 'error',
  Engaged = 'engaged',
  Fatal = 'fatal',
  HoldDropped = 'hold-dropped',
  NoAnswer = 'no-answer',
  Skipped = 'skipped',
  InvalidEndpoint = 'invalid-endpoint',
  RequiredAgentUnavailable = 'required-agent-unavailable',
  Excluded = 'excluded',
  Removed = 'removed',
  AwaitingCallback = 'awaiting-callback',
  MissedCallback = 'missed-callback',
  Snoozed = 'snoozed',
}

export interface UpdateCampaignGeneralSettings {
  name?: string;
  description?: string;
  isActive?: boolean;
  defaultListId?: number;
  trunk?: string;
  sipSourcePhoneNumber?: string;
}

// Campaign details
export interface Campaign {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  defaultListId: number;
  numLists: number;
  numCompleted: number;
  progress: number;
  archived: string;
  requiredSkills: string[];
  lists: LeadListItem[];
  previewSettings?: CampaignPreviewSettings;
  predictiveSettings?: CampaignPredictiveSettings;
  leadEngineSettings?: LeadEngineSettings;
  smsSettings?: SmsSettings;
  campaignTrunks?: CampaignTrunk[];
  dispositions: Disposition[];
  trunk: string | null | undefined;
  sipSourcePhoneNumber: string;
}

export type CampaignListItem = Pick<Campaign, 'id' | 'name' | 'numLists' | 'isActive' | 'archived'> & {};

export type CreateCampaign = Pick<Campaign, 'name' | 'description' | 'sipSourcePhoneNumber' | 'predictiveSettings'> & {
  accessFilterIds: number[] | undefined;
};

export interface DiallerGroup {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  archived: string | undefined;
  diallerType: DiallerType;
  campaignType: CampaignType;
  routingProfiles: string[];
  defaultCampaignId: number;
  enableManualOutbound: boolean;
  manualOutboundRequireDetails: boolean;
  enablePreparedOutboundHangup: boolean;
  enableManualOutboundWhenOffline: boolean;
  systemDisposedInboundCalls: boolean;
  voicemailMessageARN: string | null;
  previewSettings: DiallerGroupPreviewSettings | undefined;
  predictiveSettings: DiallerGroupPredictiveSettings | undefined;
  numCampaigns: number | undefined;
  numLeads: number | undefined;
  numAgents: number | undefined;
  needsOutboundNumber: number;
}

export type CreateDiallerGroup = Omit<
  DiallerGroup,
  | 'id'
  | 'isActive'
  | 'archived'
  | 'numCampaigns'
  | 'numLeads'
  | 'numAgents'
  | 'defaultCampaignId'
  | 'needsOutboundNumber'
> & {
  accessFilterIds: number[] | undefined;
};
export type UpdateDiallerGroup = Omit<
  DiallerGroup,
  'id' | 'archived' | 'numCampaigns' | 'numLeads' | 'numAgents' | 'needsOutboundNumber'
>;

// Lead List, list view item
export interface LeadListItem {
  id: number;
  name: string;
  isActive: boolean;
  isDefaultList: boolean;
  totalLeads: number;
  numCompleted: number;
  progress: number;
  startTime: string;
  expiryTime: string;
  numAttempts: number;
  statusCounts: Record<string, string>;
  aht: number;
  penetrationRate: number;
}

export interface CreateLeadList {
  name: string;
  description: string;
  priority: number;
  startTime?: string;
  endTime?: string;
}

export interface UpdateLeadListGeneralSettings {
  name: string;
  description: string;
  priority: string;
  isActive: boolean;
  startTime?: string;
  endTime?: string;
}

// TODO: Update with lead item based information
// Lead List details
export interface LeadList {
  id: number;
  name: string;
  description: string;
  priority: number;
  isActive: boolean;
  isDefaultList: boolean;
  totalLeads: number;
  numCompleted: number;
  progress: number;
  startTime: string;
  endTime: string;
  numAttempts: number;
  statusCounts: Record<string, number>;
  aht: number;
  penetrationRate: number;
  requiredSkills: string[];
}

export interface Lead {
  id: number;
  listId: number;
  campaignId: number;
  externalId: string;
  name: string;
  leadStatus: LeadStatusType;
  leadStatusDetail: string;
  priority: number;
  timezone: string;
  addedTimestamp: string;
  startTimestamp: string | null;
  expirationTimestamp: string | null;
  recheckTimestamp: string | null;
  endpoints: string[];
  numAttempts: number;
  attempts: LeadAttempt[];
  requiredSkills: string[];
  attributes: Record<string, string>;
  // key of the map being the endpoint
  endpointAttributes: Record<string, LeadEndpointAttributes>;
}

export interface LeadEndpointAttributes {
  type: string;
  status: LeadEndpointStatusType;
  recheckTimestamp: string;
  attributes: Record<string, string>;
}

export interface LeadAttempt {
  timestamp: string;
  outcome: string | null;
  dispositionCode: string | null;
  dispositionSubCode: string | null;
  contactId: string | null;
  callbackId: number | null;
  endpoint: string;
  creationContext: AttemptCreationContext;
}

export interface LeadListUploadResponse {
  importId: number;
  importErrorCount: number;
  importWarningCount: number;
  parseErrorCount: number;
  recordCount: number;
  validCount: number;
}

export interface ListImportItem {
  importId: number;
  importRecordNumber: number;
  importError: string | null;
  importWarning: string | null;
  originalData: string | null;
  parseError: string | null;
  parsed: string | null;
}
export interface DiallerGroupPreviewSettings {
  enableEndpointSelection: boolean;
  pushPreviewSeconds: number;
  ringOutSeconds: number;
}

export interface CampaignPreviewSettings {
  agentAssignedTimeoutSeconds: number;
}

export interface DiallerGroupPredictiveSettings {
  ringTimeSeconds: number;
  holdDropSeconds: number;
  maxLinesPerAgent: number;
  lookaheadTimeSeconds: number;
  numReservedAgents: number;
  /** The connect queue associated with this group */
  outboundQueue: string | null | undefined;
}

export interface CampaignPredictiveSettings {
  leaveMessageOnAttempt: string | null | undefined;
  answeringMachineMessage: string | null | undefined;
  allCallbacksAsPreview: boolean | undefined;
  connectSourcePhoneNumber: string | null | undefined;
}

export interface CampaignTrunk {
  timezone: string;
  trunk: string;
}

export interface LeadEngineSettings {
  callbackRescheduleSeconds: number;
  callbackMaxReschedules: number;
  callbackShiftLeadExpiry: boolean;
  reassignMissedCallbackToGroup: boolean;
  duplicateSearchAllCampaigns: boolean;
  washEndpointOnly: boolean;
  diallingHourProfile: string;
  diallingHours?: DiallingHours;
  duplicateSearchDays: number;
  endpointAttemptIntervalSeconds: number;
  endpointMachineIntervalSeconds: number;
  endpointEngagedIntervalSeconds: number;
  endpointNoAnswerIntervalSeconds: number;
  endpointDailyMaxAttempts: number;
  externalIdAttemptIntervalSeconds: number;
  externalIdDailyMaxAttempts: number;
  leadEndpointTotalMaxAttempts: number;
  leadTotalMaxAttempts: number;
  allowContactedDuplication: boolean;
  sortAttemptCountFirst: boolean;
  enableEndpointSequence: boolean;
  washLists: string[];
  exclusionLists: string[];
}

export interface SmsSettings {
  enabled: boolean;
  smsProvider?: string;
  replyEndpoint?: string;
  fromEndpoint?: string;
  replyGraceTimeSeconds?: number;
  midJourneyAttempts?: number;
  initialTemplate?: string;
  midJourneyTemplate?: string;
  finalJourneyTemplate?: string;
}

export interface DispositionAttributeItem {
  attribute: string;
  value: string;
}

export interface Disposition {
  code: string;
  subCode: string;
  title: string;
  outcome: OutcomeType;
  description: string;
  attributes: DispositionAttributeItem[];
}

export interface UpdateDisposition {
  originalDispositionCode: string;
  originalDispositionSubCode: string;
  disposition: Disposition;
}

export interface RoutingProfile {
  id: string;
  arn: string;
  name: string;
}

export interface WashList {
  label: string;
  value: string;
}

export interface PublicHoliday {
  name: string;
  day: number;
  month: number;
  year: number;
}

export interface DiallingDayTimes {
  startTimeHour: number;
  startTimeMin: number;
  endTimeHour: number;
  endTimeMin: number;
}

export interface DiallingHours {
  diallingDays: { [key: string]: DiallingDayTimes };
  allowPublicHolidays: boolean;
}

export interface SipQueue {
  queueID: number;
  queueName: string;
}

export interface DiallerGroupSipQueue {
  queueID: number;
  queueName?: string;
  priority: number;
  delay: number;
}

export interface EndpointSequence {
  endpointType: string;
  start: number;
  priority: number;
}

export interface EndpointSequenceResponse {
  endpointTypes: string[];
  endpointSequence: EndpointSequence[];
  enabled: boolean;
}

export const WashListResponseDecoder: j.Decoder<WashList[]> = j
  .object({
    wash_lists: j.array(
      j.object({
        label: j.string(),
        value: j.string(),
      }),
    ),
  })
  .map((resp) => resp.wash_lists || []);

export const RoutingProfileDecoder = j.object({
  name: j.string(),
  id: j.string(),
  arn: j.string(),
});

export const ListRoutingProfileResponseDecoder: j.Decoder<RoutingProfile[]> = j
  .object({
    routing_profiles: j.union(j.array(RoutingProfileDecoder), j.constant(null)),
  })
  .map((resp) => resp.routing_profiles || []);

export const GetCampaignResponseDecoder: j.Decoder<Campaign> = j
  .object({
    campaign_id: j.number(),
    name: j.string(),
    description: j.string(),
    default_list_id: j.number(),
    num_lists: j.number(),
    num_completed: j.number(),
    progress: j.number(),
    is_active: j.boolean(),
    archived: j.union(j.string(), j.constant(null)),
    required_skills: j.array(j.string()),
    trunk: j.optional(j.union(j.string(), j.constant(null))),
    sip_source_phone_number: j.optional(j.union(j.string(), j.constant(null))),
    lists: j.array(
      j.object({
        list_id: j.number(),
        name: j.string(),
        is_active: j.boolean(),
        is_default_list: j.boolean(),
        total_leads: j.number(),
        num_completed: j.number(),
        progress: j.number(),
        start_time: j.union(j.string(), j.constant(null)),
        expiry_time: j.union(j.string(), j.constant(null)),
        num_attempts: j.number(),
        status_counts: j.dict(j.number()),
        aht: j.union(j.number(), j.constant(null)),
        penetration_rate: j.number(),
      }),
    ),
    preview_settings: j.union(
      j.object({
        agent_assigned_timeout_seconds: j.number(),
      }),
      j.constant(null),
    ),
    campaign_trunks: j.array(
      j.union(
        j.object({
          timezone: j.string(),
          trunk: j.string(),
        }),
        j.constant(null),
      ),
    ),
    predictive_settings: j.union(
      j.object({
        leave_message_on_attempt: j.optional(j.union(j.string(), j.constant(null))),
        answering_machine_message: j.optional(j.union(j.string(), j.constant(null))),
        all_callbacks_as_preview: j.optional(j.boolean()),
        connect_source_phone_number: j.optional(j.union(j.string(), j.constant(null))),
      }),
      j.constant(null),
    ),
    lead_engine_settings: j.union(
      j.object({
        callback_reschedule_seconds: j.number(),
        callback_max_reschedules: j.number(),
        callback_shift_lead_expiry: j.boolean(),
        reassign_missed_callback_to_group: j.boolean(),
        duplicate_search_all_campaigns: j.boolean(),
        wash_endpoint_only: j.boolean(),
        dialling_hour_profile: j.string(),
        dialling_hours: j.optional(
          j.object({
            allow_public_holidays: j.boolean(),
            dialling_days: j.dict(
              j.union(
                j.object({
                  start_time_hour: j.number(),
                  start_time_min: j.number(),
                  end_time_hour: j.number(),
                  end_time_min: j.number(),
                }),
                j.constant(null),
              ),
            ),
          }),
        ),
        duplicate_search_days: j.number(),
        endpoint_attempt_interval_seconds: j.number(),
        endpoint_machine_interval_seconds: j.number(),
        endpoint_engaged_interval_seconds: j.number(),
        endpoint_no_answer_interval_seconds: j.number(),
        endpoint_daily_max_attempts: j.number(),
        external_id_attempt_interval_seconds: j.number(),
        external_id_daily_max_attempts: j.number(),
        lead_endpoint_total_max_attempts: j.number(),
        lead_total_max_attempts: j.number(),
        allow_contacted_duplication: j.boolean(),
        sort_attempt_count_first: j.boolean(),
        enable_endpoint_sequence: j.boolean(),
        wash_lists: j.array(j.string()),
        exclusion_lists: j.array(j.string()),
      }),
      j.constant(null),
    ),
    sms_settings: j.union(
      j.object({
        enabled: j.boolean(),
        sms_provider: j.string(),
        reply_endpoint: j.string(),
        from_endpoint: j.string(),
        reply_grace_time_seconds: j.number(),
        mid_journey_attempts: j.number(),
        initial_template: j.string(),
        mid_journey_template: j.string(),
        final_journey_template: j.string(),
      }),
      j.constant(null),
    ),
    dispositions: j.array(
      j.object({
        title: j.string(),
        description: j.string(),
        outcome: j.oneOf(
          j.constant(OutcomeType.Contacted),
          j.constant(OutcomeType.Callback),
          j.constant(OutcomeType.NoAnswer),
          j.constant(OutcomeType.AnsweringMachine),
          j.constant(OutcomeType.Engaged),
          j.constant(OutcomeType.InvalidEndpoint),
          j.constant(OutcomeType.HoldDropped),
          j.constant(OutcomeType.Excluded),
          j.constant(OutcomeType.Skipped),
          j.constant(OutcomeType.Removed),
          j.constant(OutcomeType.RequiredAgentUnavailable),
          j.constant(OutcomeType.AssignmentExpired),
          j.constant(OutcomeType.Error),
          j.constant(OutcomeType.Fatal),
          j.constant(OutcomeType.AwaitingCallback),
          j.constant(OutcomeType.MissedCallback),
          j.constant(OutcomeType.Snoozed),
        ),
        code: j.string(),
        sub_code: j.string(),
        attributes: j.array(
          j.object({
            attribute: j.string(),
            value: j.string(),
          }),
        ),
      }),
    ),
  })
  .map((item: any) => ({
    id: item.campaign_id,
    name: item.name,
    description: item.description,
    defaultListId: item.default_list_id,
    numLists: item.num_lists,
    numCompleted: item.num_completed,
    progress: item.progress * 100,
    isActive: item.is_active,
    archived: item.archived,
    requiredSkills: item.required_skills,
    trunk: item.trunk || '',
    sipSourcePhoneNumber: item.sip_source_phone_number || '',
    lists: item.lists.map(
      (list: any): LeadListItem => ({
        id: list.list_id,
        name: list.name,
        isActive: list.is_active,
        isDefaultList: list.is_default_list,
        totalLeads: list.total_leads,
        numCompleted: list.num_completed,
        progress: list.progress * 100,
        startTime: list.start_time,
        expiryTime: list.expiry_time,
        numAttempts: list.num_attempts,
        statusCounts: list.status_counts,
        aht: list.aht,
        penetrationRate: list.penetration_rate * 100,
      }),
    ),
    previewSettings: item.preview_settings
      ? {
          agentAssignedTimeoutSeconds: item.preview_settings.agent_assigned_timeout_seconds,
        }
      : undefined,
    predictiveSettings: item.predictive_settings
      ? {
          leaveMessageOnAttempt: item.predictive_settings.leave_message_on_attempt,
          answeringMachineMessage: item.predictive_settings.answering_machine_message,
          allCallbacksAsPreview: item.predictive_settings.all_callbacks_as_preview,
          trunk: item.predictive_settings.trunk || '',
          connectSourcePhoneNumber: item.predictive_settings.connect_source_phone_number,
        }
      : undefined,
    campaignTrunks: (item.campaign_trunks || []).map(
      (trunk: any): CampaignTrunk => ({
        timezone: trunk.timezone,
        trunk: trunk.trunk,
      }),
    ),
    leadEngineSettings: item.lead_engine_settings
      ? {
          callbackRescheduleSeconds: item.lead_engine_settings.callback_reschedule_seconds || 0,
          callbackMaxReschedules: item.lead_engine_settings.callback_max_reschedules || 0,
          callbackShiftLeadExpiry: item.lead_engine_settings.callback_shift_lead_expiry,
          reassignMissedCallbackToGroup: item.lead_engine_settings.reassign_missed_callback_to_group || false,
          duplicateSearchAllCampaigns: item.lead_engine_settings.duplicate_search_all_campaigns,
          washEndpointOnly: item.lead_engine_settings.wash_endpoint_only,
          diallingHourProfile: item.lead_engine_settings.dialling_hour_profile,
          diallingHours: item.lead_engine_settings.dialling_hours
            ? {
                allowPublicHolidays: item.lead_engine_settings.dialling_hours.allow_public_holidays,
                diallingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reduce(
                  (out, day) => {
                    const hrs = item.lead_engine_settings.dialling_hours.dialling_days[day];
                    return {
                      ...out,
                      [day]: hrs
                        ? {
                            startTimeHour: hrs.start_time_hour,
                            startTimeMin: hrs.start_time_min,
                            endTimeHour: hrs.end_time_hour,
                            endTimeMin: hrs.end_time_min,
                          }
                        : null,
                    };
                  },
                  {},
                ),
              }
            : undefined,
          duplicateSearchDays: item.lead_engine_settings.duplicate_search_days,
          endpointAttemptIntervalSeconds: item.lead_engine_settings.endpoint_attempt_interval_seconds,
          endpointMachineIntervalSeconds: item.lead_engine_settings.endpoint_machine_interval_seconds,
          endpointEngagedIntervalSeconds: item.lead_engine_settings.endpoint_engaged_interval_seconds,
          endpointNoAnswerIntervalSeconds: item.lead_engine_settings.endpoint_no_answer_interval_seconds,
          endpointDailyMaxAttempts: item.lead_engine_settings.endpoint_daily_max_attempts,
          externalIdAttemptIntervalSeconds: item.lead_engine_settings.external_id_attempt_interval_seconds,
          externalIdDailyMaxAttempts: item.lead_engine_settings.external_id_daily_max_attempts,
          leadEndpointTotalMaxAttempts: item.lead_engine_settings.lead_endpoint_total_max_attempts,
          leadTotalMaxAttempts: item.lead_engine_settings.lead_total_max_attempts,
          allowContactedDuplication: item.lead_engine_settings.allow_contacted_duplication,
          sortAttemptCountFirst: item.lead_engine_settings.sort_attempt_count_first,
          enableEndpointSequence: item.lead_engine_settings.enable_endpoint_sequence,
          washLists: item.lead_engine_settings.wash_lists,
          exclusionLists: item.lead_engine_settings.exclusion_lists,
        }
      : undefined,
    smsSettings: item.sms_settings
      ? {
          enabled: item.sms_settings.enabled,
          smsProvider: item.sms_settings.sms_provider,
          replyEndpoint: item.sms_settings.reply_endpoint,
          fromEndpoint: item.sms_settings.from_endpoint,
          replyGraceTimeSeconds: item.sms_settings.reply_grace_time_seconds,
          midJourneyAttempts: item.sms_settings.mid_journey_attempts,
          initialTemplate: item.sms_settings.initial_template,
          midJourneyTemplate: item.sms_settings.mid_journey_template,
          finalJourneyTemplate: item.sms_settings.final_journey_template,
        }
      : undefined,
    dispositions: item.dispositions.map((disposition: any) => ({
      title: disposition.title,
      description: disposition.description,
      outcome: disposition.outcome || undefined,
      code: disposition.code,
      subCode: disposition.sub_code,
      attributes: disposition.attributes.map((attr: any) => ({
        attribute: attr.attribute,
        value: attr.value,
      })),
    })),
  }));

export const GetLeadListResponseDecoder: j.Decoder<LeadList> = j
  .object({
    list_id: j.number(),
    name: j.string(),
    description: j.string(),
    priority: j.number(),
    is_active: j.boolean(),
    is_default_list: j.boolean(),
    total_leads: j.number(),
    num_completed: j.number(),
    progress: j.number(),
    start_time: j.union(j.string(), j.constant(null)),
    expiry_time: j.union(j.string(), j.constant(null)),
    num_attempts: j.number(),
    status_counts: j.dict(j.number()),
    aht: j.union(j.number(), j.constant(null)),
    penetration_rate: j.number(),
    required_skills: j.array(j.string()),
  })
  .map((item: any) => ({
    id: item.list_id,
    name: item.name,
    description: item.description,
    priority: item.priority,
    isActive: item.is_active,
    isDefaultList: item.is_default_list,
    totalLeads: item.total_leads,
    numCompleted: item.num_completed,
    progress: item.progress * 100,
    startTime: item.start_time,
    endTime: item.expiry_time,
    numAttempts: item.num_attempts,
    statusCounts: item.status_counts,
    aht: item.aht,
    penetrationRate: item.penetration_rate * 100,
    requiredSkills: item.required_skills,
  }));

export const GetUploadLeadListResponseDecoder: j.Decoder<LeadListUploadResponse> = j
  .object({
    import_id: j.number(),
    import_error_count: j.number(),
    import_warning_count: j.number(),
    parse_error_count: j.number(),
    record_count: j.number(),
    valid_count: j.number(),
  })
  .map((item: any) => ({
    importId: item.import_id,
    importErrorCount: item.import_error_count,
    importWarningCount: item.import_warning_count,
    parseErrorCount: item.parse_error_count,
    recordCount: item.record_count,
    validCount: item.valid_count,
  }));

export const GetListImportResponseDecoder: j.Decoder<ListImportItem[]> = j.array(
  j
    .object({
      import_id: j.number(),
      import_record_number: j.number(),
      import_error: j.union(j.string(), j.constant(null)),
      import_warning: j.union(j.string(), j.constant(null)),
      original_data: j.union(j.string(), j.constant(null)),
      parse_error: j.union(j.string(), j.constant(null)),
      parsed: j.union(j.string(), j.constant(null)),
    })
    .map((item: any) => ({
      importId: item.import_id,
      importRecordNumber: item.import_record_number,
      importError: item.import_error,
      importWarning: item.import_warning,
      originalData: item.original_data,
      parseError: item.parse_error,
      parsed: item.parsed,
    })),
);

const GetPublicHolidayItemResponseDecoder: j.Decoder<PublicHoliday> = j
  .object({
    name: j.string(),
    day: j.number(),
    month: j.number(),
    year: j.number(),
  })
  .map((item: any) => ({
    name: item.name,
    day: item.day,
    month: item.month,
    year: item.year,
  }));

export const GetPublicHolidaysResponseDecoder: j.Decoder<PublicHoliday[]> = j
  .object({
    public_holidays: j.array(GetPublicHolidayItemResponseDecoder),
  })
  .map((item: any) => item.public_holidays);

export const GetEndpointSequenceResponseDecoder: j.Decoder<EndpointSequenceResponse> = j
  .object({
    endpoint_types: j.array(j.string()),
    endpoint_sequence: j.array(
      j.object({
        endpoint_type: j.string(),
        start_attempt: j.number(),
        priority: j.number(),
      }),
    ),
    enabled: j.boolean(),
  })
  .map((item: any) => ({
    endpointTypes: item.endpoint_types,
    endpointSequence: item.endpoint_sequence.map((seq: any) => ({
      endpointType: seq.endpoint_type,
      start: seq.start_attempt,
      priority: seq.priority,
    })),
    enabled: item.enabled,
  }));

export const SipQueueResponseDecoder: j.Decoder<SipQueue> = j
  .object({
    queue_id: j.number(),
    queue_name: j.string(),
  })
  .map((item: any) => ({
    queueID: item.queue_id,
    queueName: item.queue_name,
  }));

export const DiallerGroupSipQueueResponseDecoder: j.Decoder<DiallerGroupSipQueue> = j
  .object({
    queue_id: j.number(),
    queue_name: j.string(),
    priority: j.number(),
    delay_second: j.number(),
  })
  .map((item: any) => ({
    queueID: item.queue_id,
    queueName: item.queue_name,
    priority: item.priority,
    delay: item.delay_second,
  }));

export const GetDiallerGroupSipQueuesResponseDecoder: j.Decoder<DiallerGroupSipQueue[]> = j
  .object({
    group_queues: j.array(DiallerGroupSipQueueResponseDecoder),
  })
  .map((item: any) => item.group_queues);
